import { Routes } from '@angular/router';

export const SearchRoutes: Routes = [
  {
    path: 'search-results',
    loadComponent: () =>
      import(
        /* webpackChunkName: "search-result-component" */ './pages/search-results-page/search-results-page.component'
      ).then((x) => x.SearchResultsPageComponent),
  },
];
